import User from "../../assets/icons/User";
import logo from "../../assets/images/caready-logo.webp";
import logoOnly from "../../assets/images/caready.png";
import PARLogo from "../../assets/images/PAR-logo.jpg";
import { DropdownJadwal } from "../HeaderDropdown/DropdownJadwal";
// import { DropdownNipl } from '../HeaderDropdown/DropdownNipl';
import { DropdownNotification } from "../HeaderDropdown/DropdownNotification";
import { DropdownProfile } from "../HeaderDropdown/DropdownProfile";
import HeaderLabel from "../HeaderLabel/HeaderLabel.js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import withoutAuthProvider from "../../services/withoutAuthProvider";
import toast from "react-hot-toast";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton.js";

const IconMenu = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8008_8388)">
      <path
        d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"
        fill="#1E1E1F"
      />
    </g>
    <defs>
      <clipPath id="clip0_8008_8388">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default function HeaderBottom(props) {
  const { setIsOpen } = props;
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const { push } = useHistory();

  const [icon, setIcon] = useState();

  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const toastError = (message) => {
    return toast.error(message, {
      id: "error-message",
      duration: 3000,
    });
  };

  const getCompanyIcon = async () => {
    const exclusive_seller_slug = subdomain;

    const response = await withoutAuthProvider.getDataWithoutAuth(
      `/api/auction/seller/${exclusive_seller_slug}`
    );
    if (response?.status === 200) {
      const data = response?.data.data;
      setIcon(data.exclusive_logo_file_url);
    } else {
      toastError(response?.data.message);
    }
  };

  useEffect(() => {
    getCompanyIcon();
  });

  return (
    <nav
      style={{
        backgroundColor: "#FFF",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px",
      }}
    >
      <div className="container text-small fw-normal" id="bottom-header">
        <div className="d-flex align-items-center justify-content-between py-2">
          <div className="d-flex gap-3 align-items-center">
            <button
              className="d-block d-md-none"
              style={{ background: "white", border: "none" }}
              onClick={() => setIsOpen(true)}
              onBlur={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 200)
              }
            >
              <IconMenu />
            </button>
            <div
              className="cursor-pointer header-icon d-flex align-items-center"
              onClick={() => push("/")}
            >
              <img
                src={logo}
                alt="caready logo"
                className="h-100 d-none d-md-block border-end px-3"
              />
              <img
                src={logoOnly}
                alt="caready logo"
                className="h-75 d-block d-md-none border-end px-1"
              />
              <img
                src={icon}
                alt="Company logo"
                className=" d-none d-md-block px-3"
                style={{ height: 70 }}
              />
              <img
                src={icon}
                alt="Company logo"
                className="h-75 d-block d-md-none px-1"
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-secondary">
            <div className="border-end d-none d-md-flex fw-bold ">
              <button
                type="button"
                onClick={() =>
                  window.open("https://api.whatsapp.com/send/?phone=6285891101162&text=Halo%20min,%20Saya%20mau%20daftar", "_blank")
                }
                className="btn btn-primary font-xl fw-bold"
              >
                Register CNAF
              </button>
              <HeaderLabel path="/unit-lelang">
                <p className="mb-0">Unit Lelang</p>
              </HeaderLabel>

              <DropdownJadwal />

              <HeaderLabel path="/titip-lelang">
                <p className="mb-0">Titip Lelang</p>
              </HeaderLabel>
              <HeaderLabel path="/beli-nipl">
                <p className="mb-0">Beli NIPL</p>
              </HeaderLabel>
            </div>
            {/* yang belum login/sudah */}
            <div
              className="d-flex align-items-center"
              style={{ height: "40px" }}
            >
              {isLogged ? (
                <>
                  <div className="d-flex px-2 border-end">
                    {/* <DropdownNipl /> */}
                    <DropdownNotification />
                  </div>
                  <div>
                    <DropdownProfile />
                  </div>
                </>
              ) : (
                <HeaderLabel path="/masuk">
                  <div className="d-flex align-items-center fw-bold justify-content-between">
                    <User className="text-primary" />
                    <p className="ms-3 mb-0">Masuk/Daftar</p>
                  </div>
                </HeaderLabel>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
